import React, { Component, Suspense } from 'react';
import { MainContainer, TopRecognitionContainer, TopRecognized, RecognitionHeader, RecognitionColumnHeader, Column, Row, NameContainer, ProfileImageContainer, Name, RecognitionReceivedContainer, RecognitionReceivedHeader, RecognisedUser, UserProfileContainer, RecognitionFrequency, RowWrapper, NoData, NameWrapper, UserNameContainer } from './styles';
import PropTypes from "prop-types";
import Waiting from "../../Waiting";
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { ImageUrl } from '../../../utils/constants';
import { DownloadIconNew, WellnessChampion } from '../../../utils/icons';
const LazyImage = React.lazy(() => import('../../common/LazyImage/LazyImage'));
const HoverButton = React.lazy(() => import('../../common/HoverButton'));
const CSVList = React.lazy(() => import('../../AttendeesModal/CSVList'));

class RecognitionActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: this.props.companyInfo['id'],
      printRecogizedCSV: false,
      updatedRecognizedCSV: [],
      removeCSV: 0
    }
  }

  printRecognizedEmployees = (bool, Recognizetype, index) => {
    this.setState({ topRecognizetype: Recognizetype + 1 })
    this.printAction(bool, index);
  }

  printAction = (bool, index) => {
    const { topRecognized, topReceivers } = this.props;
    if (index === 0) {
      let updatedRecognizedCSV = topRecognized && topRecognized.length > 0 &&
        topRecognized.map((recognized) => {
          let obj = {};
          obj['NAME'] = recognized['name'];
          obj['DEPARTMENT'] = recognized['department'];
          obj['LOCATION'] = recognized['location'];
          obj['RECOGNITION'] = recognized['recognition'];
          return obj;
        })
      this.setState({ printRecognizedCSV: bool, updatedRecognizedCSV: updatedRecognizedCSV })
    } else {
      let updatedRecognizedCSV = topReceivers && topReceivers.length > 0 &&
        topReceivers.map((recognized) => {
          let obj = {};
          obj['NAME'] = recognized['name'];
          obj['DEPARTMENT'] = recognized['department'];
          obj['LOCATION'] = recognized['location'];
          obj['RECOGNITION'] = recognized['recognition'];
          return obj;
        })
      this.setState({ printRecognizedCSV: bool, updatedRecognizedCSV: updatedRecognizedCSV })
    }

  };


  renderTopReceivers = (topRecognized, topReceivers) => {
    const recognitions = [
      {
        "id": 'recognized',
        "title": "Most Recognized Employees",
        "details": topRecognized,
        "no_data_message": "No Recognition Received",
        "type":"Received"
      },
      {
        "id": 'gave',
        "title": "Top Peers Who Gave Recognition",
        "details": topReceivers,
        "no_data_message": "No Recognition Received",
        "type":"Gave"
      },
    ];


    return (
      <TopRecognitionContainer style={{marginBottom: '10px'}}>
        {recognitions.map((recognition, index) => (
          <TopRecognized key={recognition.id}>
            <RecognitionHeader background={'white'}>
              <p className='recognise-title'>{this.props.selectedYearInfo} {recognition.title}</p>
              <div style={{margin:'10px 0px'}}>
                <Suspense fallback={<Waiting/>}>
                  <HoverButton className='download_img' width={"40px"} height={"40px"}  title={"Download"} svgPath={<DownloadIconNew/>} 
                    onClick={() => this.printRecognizedEmployees(true, index, index)}/>
                </Suspense>  
                {/* <img className='download_img' src={'/images/AdminChallenges/printer.png'} onClick={() => this.printRecognizedEmployees(true, index, index)} /> */}
              </div>
            </RecognitionHeader>
            <RecognitionColumnHeader>
              <Column width={"30%"} textAlign={'left'}>Name</Column>
              <Column width={"25%"} textAlign={'center'}>Department</Column>
              <Column width={"25%"} textAlign={'center'}>Location</Column>
              <Column width={"14%"} textAlign={'center'}>{recognition.type}</Column>
            </RecognitionColumnHeader>
            <RowWrapper>
              {recognition.details.length > 0 ? recognition.details.map((data, index) => (
                index < 10 &&
                <Row key={index} >
                  <NameContainer width={"30%"} position={'relative'} right={'5px'}>
                    <ProfileImageContainer>
                      <Suspense fallback={<Waiting/>}><LazyImage src={ImageUrl + "/" + data.profile_image} className={ index === 0 && "employee"} style={{borderRadius:'3px'}}/></Suspense>
                    </ProfileImageContainer>
                    <NameWrapper>
                      <Name width={'100%'} margin={data.is_champion === 1} color={"#005c87"} style={{ marginTop: data.is_champion === 1 && '7px 0px 3px 0px' }}>{data.name}</Name>
                      {index === 0 && <span>Employee of the Year</span>}
                      {data.is_champion && <WellnessChampion/>}
                    </NameWrapper>
                  </NameContainer>
                  <NameContainer width={"25%"} textAlign={'center'} justifyContent={'center'} >
                    <Name
                      style={{ marginTop: '0px' }} fontFamily={'Rubik-Regular'} color={'#005c87'} width={'100%'}>{data.department || 'NA'}</Name>
                  </NameContainer>
                  <NameContainer width={"25%"} textAlign={'center'} justifyContent={'center'}>
                    <Name
                      style={{ marginTop: '0px' }}
                      fontFamily={'Rubik-Regular'} color={'#005c87'} width={'100%'}>{data.location || 'NA'}</Name>
                  </NameContainer>
                  <NameContainer width={"14%"} textAlign={'center'} justifyContent={'center'}>
                    <Name
                      style={{ marginTop: '0px' }}
                      fontFamily={'Rubik-Regular'} color={'#005c87'} width={'100%'}>{data.recognition || 'NA'}</Name>
                  </NameContainer>
                </Row>
              )) : <NoData>{recognition.no_data_message}</NoData>}
            </RowWrapper>
          </TopRecognized>
        ))}
      </TopRecognitionContainer>
    )
  }

  fallBack = (e) => {
    e.target.src = "/public/images/default.png";
  };

  renderRecognitionReceived = () => {
    const { companyRecognitions, /*recognitionDetails*/ } = this.props;
    return (
      <TopRecognitionContainer justify="1" style={{marginBottom: '25px'}}>
        {companyRecognitions ? companyRecognitions?.map((recognition, index) => recognition?.data && recognition?.data.length > 0 && (
          <RecognitionReceivedContainer key={index}>
            {<RecognitionReceivedHeader>
              <Suspense fallback={<Waiting/>}><LazyImage src={`${ImageUrl}/${recognition.icon}`}/></Suspense>
              <span>{recognition.recognition}</span>
              <div>{this.props.selectedYearInfo}</div>
            </RecognitionReceivedHeader>}
            {recognition?.data && recognition?.data?.length > 0 && recognition?.data.map((data, index) => (
              index < 5 && <RecognisedUser key={data.uid}>
                <UserProfileContainer style={{ display: 'flex', alignItems: 'center' }} active={index === 2}>
                  <Suspense fallback={<Waiting/>}><LazyImage src={ImageUrl + "/" + data.profile_image} className={ index === 0 && "employee"} fallBack={this.fallBack}/></Suspense>
                  <UserNameContainer>
                    <Name fontFamily={'Rubik-Medium'} color={'#005c87'} textAlign={1} margin={data.is_champion === 1}
                      active={index == this.state.activeIndexRow2}>{data.name}</Name>
                    {index === 0 && <span>Employee of the Value</span>}
                    {data.is_champion && <WellnessChampion/>}
                  </UserNameContainer>
                </UserProfileContainer>
                <RecognitionFrequency active={index === 2}>
                  <span color={'#005c87'}>{data.recognition_count ? data.recognition_count === 1 ? `${data.recognition_count} time` : `${data.recognition_count} times` : '0 time'}</span>
                </RecognitionFrequency>
              </RecognisedUser>
            )) 
            // : <NoData>No data to display</NoData>
            }
          </RecognitionReceivedContainer>
        )) : null}
      </TopRecognitionContainer>
    )
  };
  render() {
    const { companyInfo, topRecognized, topReceivers, getRecognitionActivityDetails, companyRecognitions } = this.props;
    const { printRecognizedCSV, updatedRecognizedCSV, topRecognizetype } = this.state;
    if (isNull(companyInfo) || isUndefined(companyInfo) || isNull(topRecognized)
      || isUndefined(topRecognized) || isNull(topReceivers) || isUndefined(topReceivers) || isNull(getRecognitionActivityDetails) || isNull(getRecognitionActivityDetails) || isUndefined(getRecognitionActivityDetails)
      || isUndefined(getRecognitionActivityDetails) || isNull(companyRecognitions) || isUndefined(companyRecognitions)) {
      return <Waiting />
    }
    const fileTitle = topRecognizetype === 1 ? "Most Recognized Employees" : "Top Peers Who Gave Recognition";
    const csvHeaders = topRecognizetype === 1 ?
      [
        { label: "Name", key: "NAME" },
        { label: "Department", key: "DEPARTMENT" },
        { label: "Location", key: "LOCATION" },
        { label: "Recognition Received ", key: "RECOGNITION" },
      ] :
      [
        { label: "Name", key: "NAME" },
        { label: "Department", key: "DEPARTMENT" },
        { label: "Location", key: "LOCATION" },
        { label: "Recognition Given ", key: "RECOGNITION" },
      ]
    return (
      <MainContainer>
        {this.renderTopReceivers(topRecognized, topReceivers)}
        {this.renderRecognitionReceived()}
        {printRecognizedCSV && topRecognizetype && updatedRecognizedCSV
          && <Suspense fallback={<Waiting/>}><CSVList Title={fileTitle} removeCSVData={this.printAction} headers={csvHeaders} data={updatedRecognizedCSV} /></Suspense>
        }
      </MainContainer>
    );
  }
}
RecognitionActivity.propTypes = {
  employeeData: PropTypes.array,
  companyInfo: PropTypes.object,
  companyId: PropTypes.number,
  fetchSearchPeople: PropTypes.func,
  getRecognitionActivityDetails: PropTypes.object,
  topRecognized: PropTypes.array,
  topReceivers: PropTypes.array,
  companyRecognitions: PropTypes.array,
  recognitionDetails: PropTypes.array,
  selectedYearInfo: PropTypes.number
};

export default RecognitionActivity;

