import styled, {css} from "styled-components";
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const MainContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 15px;
`;
const ActivityContainer = styled.div`
  float: left;
  width: 100%;
  display: block;
  @media (max-width: 1225px) and (min-width: 501px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const MainActivityContainer = styled.div`
  float: left;
  height: ${({height}) => height ? height : '198px'};
  width: calc((100% - 52px)/3);
  display: inline-block;
  margin-right: 26px;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 20px;
  &:nth-child(3) {
    margin-right: 0;
  }
  @media (max-width: 1100px) {
    width: calc((100% - 36px)/2);
    &:nth-child(2) {
     margin-right: 0;
   }
  }
  
  @media (max-width: 750px) {
   width: calc((100% - 36px)/2);
   &:nth-child(2) {
     margin-right: 0;
   }
  }
   @media(max-width:705px) and (min-width:601px) {
      margin-bottom: 30px;
   }
  @media (max-width: 600px) {
    width: 100%;
    margin-right: 0;
    height: ${({height}) => height ? height : 'unset'};
  }
  }
`;
const ActivityCardHeader = styled.div`
    padding: 20px 0;
    & > P{
      color: #6F767E;
      font-family: ${BoldFont};
      font-weight: 600;
      font-size: 13px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 1px;
      margin: 0;
   }
   @media(max-width: 1215px) and (min-width: 1101px) {
      min-height: 74px;
   }
   @media(max-width:705px) and (min-width:601px) {
      min-height: 74px;
   }
 `;

const TopRecognitionContainer = styled.div`
		flex-wrap: wrap;
    display: flex;
    gap: 25px;
    width: 100%;
    margin: 0px 0;
    justify-content: ${({justify}) => justify ? '' : 'space-between'};
`;

const TopRecognized = styled.div`
   float: left;
   /* margin-right: 10px; */
   width: calc((100% - 25px) / 2);
   margin: 10px 0px 0px 0;
   &:nth-child(2) {
    margin-right: 0;
  }
   
   @media(max-width: 1200px) {
    width: 100%;
    margin-right: 0;
   }
`;

const RecognitionHeader = styled.div`
  width: 100%;
  background: ${({background}) => background ? background : 'white'};
  border-radius: 6px 6px 0px 0px;
  color: #fff;
  padding: 1px 15px;
  font-family: ${BoldFont};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  .recognise-title{
    height: 24px;
    left: 452px;
    top: 460px;    
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;   
    color: #005c87;
    margin-top: 15px;
    
  }
  >div{
      border: none;
      background: none;
    .download_img{
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-top: 9px;
    }
  }
`;

const RecognitionColumnHeader = styled.div`
  width: 100%;
  background: #fff;
  padding: 3px 16px;
  float: left;
  background: #f2f7f9;
  height: 30px;
  display: flex;
  justify-content: space-between;
  
  @media(max-width: 500px) {
    padding: 0
  }
`;

const Column = styled.div`
 width: ${({width}) => width ? width : '0px'};
 text-align: ${({textAlign}) => textAlign ? textAlign : 'center'};
//  color: #282C37;
color: #619ab5;
 opacity: 1;
 float: left;
 font-size: 16px;
 line-height: 24px;
 font-family: 'Rubik-Regular';
 &:first-child {
  margin-left: ${({marginLeft}) => marginLeft ? marginLeft : '40px'};
  width: calc(45% - 40px);
 }
 &:last-child {
  text-align: center;
 }
 @media(max-width: 500px) {
   display: none;
 }
`;

const Row= styled.div`
  width: 93%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 15px 10px; */
  background: white;
  cursor: pointer;
  min-height: 80px;
  border-bottom: 1px solid #b3cfdb;
  &:last-child {
    border-bottom: none;
   }
  /* padding-left: 16px; */
  ${({ active }) => active && css`
    background-color: rgba(71,152,217,0.1);
  `}
   @media(max-width:500px) {
     display: inline-block;
   }
`;

const NameContainer = styled.div`
  width: ${({width}) => width ? width : '0px'};
  text-align: ${({textAlign}) => textAlign ? textAlign : 'inherit'};
  position: ${({position}) => position ? position : 'inherit'};
  right: ${({right}) => right ? right : '0px'};
  font-family: ${RegularFont};
  color :#9C9C9C;
  font-size: 14px;
  float: left;
  word-break: break-all;
  display: flex;
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'start'};
  align-items: center;
  &:last-child {
   text-align: center;
  }
  @media(max-width:500px) {
   float: none;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   width: 100%;
   display: inline-block;
   margin: 0 auto;
   padding: 5px 0;
  }
  /* > p {
    font-family: Rubik;
    font-size: 10px;
    color: #1E76AB;
    margin-left: 40px;
    width: calc(100% - 52px);
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    background-size: 100% 100%;
    color: rgb(255, 255, 255);
    background-image: url(/images/PeoplePage/Ribbon_img.png);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 13px;
    line-height: 9px;
    width: 121px;
    
    @media(max-width: 500px){
      text-align: center;
      width: 100%;
      margin-left: 0;
    }   */
  }
`;

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 10px;
  border-radius: 3px;
  div{
    & > img {
    width: 50px;
    height: 50px;
  }

  }
  & > img {
    width: 50px;
    height: 50px;
  }
  @media(max-width: 500px) {
    float: none;
  }
  .employee{
    border: 2px solid transparent; 
    background-image: linear-gradient(133.03deg, #AF87C1 25.62%, #6781C3 73.49%);
  }
`;

const NameWrapper = styled.div`
  width: "100%";
  display: flex;
  flex-direction: column;

  > span{
  width: 103px;
  height: 14px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  background: linear-gradient(133.03deg, #AF87C1 25.62%, #6781C3 73.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 2px;
  }

  > p {
    font-family: Rubik;
    font-size: 10px;
    color: #1E76AB;
    /* margin-left: 40px; */
    width: calc(100% - 52px);
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    background-size: 100% 100%;
    color: rgb(255, 255, 255);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    width: 132.12px;
    height: 18px;
    margin-bottom: 0px;
    
    @media(max-width: 500px){
      text-align: center;
      width: 100%;
      margin-left: 0;
    }  
`

const Name = styled.div`
   float: left;
   font-family: ${({fontFamily}) => fontFamily ? fontFamily : 'Rubik-Medium'};
   font-size: 14px;
   color: ${({color}) => color ? color : '#0D4270'};
   max-width: 140px;
   white-space: nowrap; 
   overflow: hidden;
   text-overflow: ellipsis;
   margin: ${({margin}) => margin ? '7px 0px 0px 0px' : '16px 0 0 0'};
   margin-top: ${({marginTop}) => marginTop ? marginTop : '0px'};
   ${({active}) => active && css`
    color: rgb(21,159,202);
  `}
   @media (max-width: 500px) {
    text-align:  ${({textAlign}) => textAlign ? 'left' : 'center'};
    width: auto;
    float: none;
    max-width: none;
   }
`;

const RecognitionReceivedContainer =  styled.div`
 float: left;
 width: calc((100% - 50px) / 3);
//  margin-right: 25px;
 /* margin-bottom: 23px; */
 background-color: #fff;
 margin-right:  ${({marginRight}) => marginRight ? marginRight : '0px'};
 display: flex;
 flex-direction: column;
 align-items: center;
 border-radius: 0px 0px 6px 6px;
//  &:last-child {
//   margin-right: 0;
//  }
 
 @media (max-width: 1200px) {
  width: calc((100% - 20px) / 2);
 }

 @media (max-width: 1260px)  and (min-width: 1200px){
	width: calc((100% - 30px) / 3);
 }
 
 @media (max-width: 575px) {
  width: 100%;
  margin- right: 0;
 }
`;

const RecognitionReceivedHeader = styled(RecognitionHeader)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // width: 405px;
  height: 50px !important;
  background: #005c87;
  border-radius: 6px 6px 0px 0px;

  div{
    > img {
		float: left;
		width: 22px;
    height: 22px;
  }

  }
  
  > img {
		float: left;
		width: 22px;
    height: 22px;
  }
  
  > span {
    float: left;
		margin-left: 10px;
		text-transform: capitalize;
    width: 80%;
  }
`;

const RecognisedUser = styled.div`
   width: 93%;
   height: 70px;
   background-color: #fff;
   /* float: left; */
   display: flex;
   align-items: center;
   border-bottom: 1px solid #E0E0E7;
   &:last-child {
    border-bottom: none;
   }
`;

const UserNameContainer = styled.div`
width:'100%';
display: flex;
flex-direction: column;
> span{
  width: 103px;
  height: 14px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top: 2px;
}
> p {
    font-family: "Rubik";
    font-size: 10px;
    color: #1E76AB;
    /* margin-left: 40px; */
    width: calc(100% - 52px);
    display: block;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    background-size: 100% 100%;
    color: rgb(255, 255, 255);
    background-image: url(/images/wellness.png);
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    line-height: 9px;
    width: 132.12px;
    height: 18px;
    margin-bottom: 0px;
   }
   
   & > p:nth-child(3) {
    font-family: Rubik-Regular;
    font-size: 10px;
    // color: #1E76AB;
    margin: 0;
    width: calc(100% - 144px);
    display: block;
    padding-top: 1px;

`

const UserProfileContainer = styled.div`
   width: 70%;
   float: left;
    > img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    float: left;
   }
   div{
    > img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
    float: left;
   }
   }
   .wellnessChampion {
      margin: 0;
   }
    @media (max-width: 1200px) {
     float: left;
     margin: 0
     min-width: 110px;
    }
   }
   
   .active {
     color: rgb(21,159,202);
   }
   .employee{
    border: 2px solid transparent; 
    background-image: linear-gradient(133.03deg, #AF87C1 25.62%, #6781C3 73.49%);
  }
`;

const RecognitionFrequency = styled.div`
   width: 30%;
   float: left;
   text-align: right;
   
   > span {
    font-family: ${BoldFont};
    font-size: 14px;
    color: ${({color}) => color ? color : '#005c87'};
   }
`;

const RowWrapper = styled.div`
  float: left;
  background: #fff;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 0px 0px 6px 6px;
}

`;

const NoData = styled.p`
	display: flex;
	padding: 30px;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  font-family: ${MediumFont};
  font-size: 14px;
  background: #fff;
`;

export {MainActivityContainer, ActivityContainer, MainContainer, ActivityCardHeader, TopRecognitionContainer, TopRecognized, RecognitionHeader, RecognitionColumnHeader, Column, Row, NameContainer, ProfileImageContainer, Name, RecognitionReceivedContainer, RecognitionReceivedHeader, RecognisedUser, UserProfileContainer, RecognitionFrequency, RowWrapper, NoData,NameWrapper,UserNameContainer};